define("discourse/plugins/swapd-pm-notes/discourse/connectors/topic-above-suggested/pm-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    // setupComponent({model}, component) {
    //   component.set("topic", model);
    // }
  };
});