define("discourse/plugins/swapd-pm-notes/discourse/components/edit-pm-note", ["exports", "discourse/lib/ajax-error", "discourse/lib/ajax", "discourse-common/utils/decorators", "discourse/lib/formatter", "@ember/component", "discourse-common/helpers/html-safe"], function (_exports, _ajaxError, _ajax, _decorators, _formatter, _component, _htmlSafe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("notes.time"), (_obj = {
    classNames: ["edit-pm-note"],
    editing: false,
    saving: false,
    note_copy: "",
    actions: {
      edit() {
        this.set("editing", true);
        const notes = this.get("notes");
        if (notes) {
          this.set("note_copy", notes.note);
        }
      },
      cancel() {
        if (this.get("saving")) return;
        this.set("editing", false);
        this.set("note_copy", "");
      },
      save() {
        if (this.get("saving")) return;
        this.set("saving", true);
        (0, _ajax.ajax)(`/pm-notes/${this.get("topic.id")}`, {
          type: "POST",
          data: {
            note: this.get("note_copy")
          }
        }).then(result => {
          this.set("notes", result);
          this.set("editing", false);
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("saving", false);
        });
      }
    },
    date(time) {
      if (!time) return;
      const date = new Date(time * 1000);
      return (0, _htmlSafe.default)((0, _formatter.relativeAge)(date, {
        format: "medium-with-ago"
      }));
    }
  }, (_applyDecoratedDescriptor(_obj, "date", [_dec], Object.getOwnPropertyDescriptor(_obj, "date"), _obj)), _obj)));
});